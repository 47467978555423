import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { LbTargetType } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { ContentPane } from "../../components/ContentPane.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { ResourceWithIcon } from "../../components/ResourceWithIcon.tsx";
import { Separator } from "../../components/Separator.tsx";
import { Spacer } from "../../components/Spacer.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import TC from "../../components/table/Table.module.css";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { ViewTitle } from "../../components/Title.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";
import { LocationDetail } from "../../modules/location/LocationDetail.tsx";
import { convertTargetType } from "../../modules/network/loadbalancer/loadBalancer.logic.ts";
import { AdminLinkButton } from "../AdminLinkButton.tsx";
import type { GQLoadBalancerItem } from "./LoadBalancersTable.tsx";

export function LoadBalancerDetailView({ item }: { item: GQLoadBalancerItem }) {
    return (
        <>
            <LoadBalancerDetails item={item} />
            <Separator />
            <ContentPane>
                <h2 className="font-size-subtitle">Description</h2>
                <p>
                    High availability load balancer distributes traffic between virtual machines within the same data
                    center location.
                </p>
            </ContentPane>
            <Separator />
            <TargetServersContent obj={item} />
            <Separator />
            <ForwardingRulesContent obj={item} />
        </>
    );
}

function LoadBalancerDetails({ item }: { item: GQLoadBalancerItem }) {
    const { uuid, display_name, private_address, _location, billing_account } = item;

    return (
        <ContentPane className="VStack gap-4">
            <ViewTitle
                title={display_name ?? ""}
                // TODO: Remove global margins from all headings and instead use container-based layouts (eg. gap)
                // (otherwise it's impossible to lay out optional (nullable) components nicely without passing additional margin properties into every one of them)
                className="important:pb-0 important:min-h-auto"
            />

            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableRow title="Name:">{display_name}</DetailsTableRow>

                    <DetailsTableRow title="Type:">
                        <ResourceWithIcon type="load_balancer" />
                    </DetailsTableRow>

                    <DetailsTableRow title="Private IPv4:">{private_address}</DetailsTableRow>
                </DetailsTable>

                <Spacer />

                <DetailsTable>
                    <DetailsTableRow title="UUID:">
                        <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                    </DetailsTableRow>

                    <DetailsTableRow title="Created:">
                        <LongDate date={apiUnixToDate(item.created_at as number)} />
                    </DetailsTableRow>

                    <LocationDetail slug={notNull(_location)} />

                    <DetailsTableRow title="Billing account:">
                        <AdminLinkButton
                            isMissing={!billing_account?.title}
                            action={todoAction}
                            label={<span>{billing_account?.title}</span>}
                        />
                    </DetailsTableRow>
                </DetailsTable>
            </DetailsHolder>
        </ContentPane>
    );
}

function TargetServersContent({ obj }: { obj: GQLoadBalancerItem }) {
    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Target servers</h2>

            <WTable>
                <thead>
                    <tr>
                        <th>UUID</th>
                        <th>Type</th>
                        <th>Private IP</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <WTableBody>
                    {obj.targets.map((item) => {
                        return (
                            <tr key={item.target_uuid}>
                                <td>
                                    <ClipBoardTooltip>{item.target_uuid}</ClipBoardTooltip>
                                </td>
                                <td>
                                    <ResourceWithIcon type={convertTargetType(item.target_type as LbTargetType)} />
                                </td>
                                <td>
                                    <ClipBoardTooltip>{item.target_ip_address}</ClipBoardTooltip>
                                </td>
                                <td>
                                    <LongDate date={apiUnixToDate(item.created_at as number)} />
                                </td>
                            </tr>
                        );
                    })}
                </WTableBody>
            </WTable>
        </ContentPane>
    );
}

function ForwardingRulesContent({ obj }: { obj: GQLoadBalancerItem }) {
    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Forwarding rules</h2>

            <WTable className={TC.rules}>
                <thead>
                    <tr>
                        <th>UUID</th>
                        <th>Protocol</th>
                        <th className="text-right">Port</th>
                        <th />
                        <th>Target port</th>
                        <th>Settings</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <WTableBody>
                    {obj.forwarding_rules.map((item) => (
                        <tr key={item.uuid}>
                            <td>
                                <ClipBoardTooltip>{item.uuid}</ClipBoardTooltip>
                            </td>
                            <td>TCP</td>
                            <td className="text-right">{item.source_port}</td>
                            <td className="text-center">
                                <MaskIcon className="jp-arrow-thin-right-icon size-1.25rem color-muted" />
                            </td>
                            <td>{item.target_port}</td>
                            <td>
                                Connection limit: {item.settings.connection_limit}
                                <br />
                                Session persistence: {item.settings.session_persistence}
                            </td>
                            <td>
                                <LongDate date={apiUnixToDate(item.created_at as number)} />
                            </td>
                        </tr>
                    ))}
                </WTableBody>
            </WTable>
        </ContentPane>
    );
}
