import { useQuery } from "@apollo/client";
import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import { BillingAccountBlock } from "../accounts/BillingAccountBlock.tsx";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import { TextFilter, UnixDateRangeFilter } from "../filters.tsx";
import { gql } from "../graphql.gen/gql.ts";
import { LoadBalancerOrderFields, OrderDirection, type GetLoadBalancerListQuery } from "../graphql.gen/graphql.ts";
import { extractData } from "../graphql/extractData.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { useCursorPager } from "../Pager.tsx";
import { baIdField, locationField, userIdField } from "../table_fields/standardFields.tsx";
import { whenNotNull } from "../table_fields/whenNotNull.tsx";
import { LoadBalancerDetailView } from "./LoadBalancerDetailView.tsx";

const GET_LOAD_BALANCERS = gql(/* GraphQL */ `
    query GetLoadBalancerList(
        $limit: Int
        $orderField: LoadBalancerOrderFields
        $orderDir: OrderDirection
        $cursor: String
        $page: Int
        $locations: [String!]
        $search: String
        $filters: [LoadBalancerFilter!]
    ) {
        admin_load_balancer_list(
            limit: $limit
            orderField: $orderField
            orderDir: $orderDir
            cursor: $cursor
            page: $page
            locations: $locations
            search: $search
            filters: $filters
        ) {
            items {
                uuid
                display_name
                network_uuid
                user_id
                billing_account_id
                billing_account {
                    ...BillingAccountBlock
                }
                created_at
                updated_at
                private_address
                forwarding_rules {
                    uuid
                    source_port
                    target_port
                    protocol
                    settings {
                        connection_limit
                        session_persistence
                    }
                    created_at
                }
                targets {
                    target_uuid
                    created_at
                    target_type
                    target_ip_address
                }
                _location
            }
            paging {
                total
                cursor
            }
        }
    }
`);

export type GQLoadBalancerItem = NonNullable<GetLoadBalancerListQuery["admin_load_balancer_list"]["items"]>[number];

const lbFields: GqlFieldsOf<GQLoadBalancerItem, LoadBalancerOrderFields> = [
    gf({
        id: "uuid",
        title: "UUID",
        get: (a) => a.uuid,
        copyable: true,
        order: LoadBalancerOrderFields.Uuid,
        filter: TextFilter,
    }),
    gf({
        id: "name",
        title: "Name",
        get: (a) => a.display_name,
        order: LoadBalancerOrderFields.DisplayName,
        filter: TextFilter,
    }),
    gf({
        id: "network_uuid",
        title: "Network UUID",
        get: (a) => a.network_uuid,
        copyable: true,
        order: LoadBalancerOrderFields.NetworkUuid,
        filter: TextFilter,
    }),
    gf({
        ...userIdField,
        get: (a) => a.user_id,
        order: LoadBalancerOrderFields.UserId,
    }),
    gf({
        ...baIdField,
        get: (a) => a.billing_account_id,
        order: LoadBalancerOrderFields.BillingAccountId,
    }),
    gf({
        id: "billing_account",
        title: "Billing Account",
        get: (a) => a.billing_account,
        render: whenNotNull(BillingAccountBlock),
    }),
    gf({
        id: "created",
        title: "Created At",
        get: (a) => (a.created_at ? apiUnixToDate(a.created_at as number) : undefined),
        order: LoadBalancerOrderFields.CreatedAt,
        filter: UnixDateRangeFilter,
    }),
    gf({
        id: "private_address",
        title: "Private IP",
        get: (a) => a.private_address,
        copyable: true,
        order: LoadBalancerOrderFields.PrivateAddress,
        filter: TextFilter,
    }),
    gf({
        ...locationField,
        get: (a) => a._location,
    }),
];

export function LoadBalancersTable() {
    return (
        <GraphqlTable
            title="Load Balancers"
            fields={lbFields}
            defaults={{ orderField: LoadBalancerOrderFields.CreatedAt, orderDir: OrderDirection.Desc }}
            getId={(item) => item.uuid}
            usePager={useCursorPager}
            useQuery={(variables) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const q = useQuery(GET_LOAD_BALANCERS, { variables });
                return extractData(q, (d) => d.admin_load_balancer_list);
            }}
            renderDetail={(item) => <LoadBalancerDetailView item={item} />}
        />
    );
}
